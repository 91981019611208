<template>
  <div class="channelSuccess" v-loading="loading">
    <div class="list" v-if="channelList.length > 0">
      <div class="cardItem"
           v-for="(item,index) in channelList"
           :key="index"
           @click="gotoConfig(item)"
      >
        <img :src="getUrl(item.wechatType)" v-if="item.wechatType !== 13 && item.wechatType !== 20">
        <i :class="getUrl(item.wechatType)"></i>
        <div class="cardName">{{ sourceName(item) }}</div>
        <div class="agentName">{{item.agentName}}</div>
        <div class="companyName">{{item.corpName}}</div>
      </div>
    </div>
    <div class="empty" v-else>
      <img src="../../assets/images/emptyImage.png">
      <div class="tips">{{$t('channel.noAccess')}}</div>
      <div class="accessBtn" @click="access">{{$t('channel.access')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "channelSuccess",
  data(){
    return{
      channelList:[],
      askBot:[
        {
          agentName:"更好的用户体验与更强大的数字员工。",
          wechatType:"askBot"
        },
        {
          agentName: "可设置即时通讯IM智能助手使用哪些机器人。",
          wechatType: 20
        }
      ],
      loading:true
    }
  },
  methods:{
    sourceName(item){
      if (item.wechatType == 0){
        return this.$t('channel.wechat.name')
      } else if (item.wechatType == 1){
        return this.$t('channel.wechatAccount.name')
      } else if (item.wechatType == 2){
        return this.$t('channel.miniProgram.name')
      } else if (item.wechatType == 3){
        return this.$t('channel.dingding.name')
      } else if (item.wechatType == 4){
        return this.$t('channel.website.other')
      }  else if (item.wechatType == 5){
        return '微信客服'
      }  else if (item.wechatType == 6 || item.wechatType == 7){
        return this.$t('channel.dingdingGroup.name')
      } else if (item.wechatType == 9){
        return this.$t('channel.dingSingleChatBot.name')
      } else if (item.wechatType == 10){
        return this.$t('channel.feishu.name')
      } else if (item.wechatType == 13){
        return this.$t('channel.yunzhijia.name')
      } else if (item.wechatType == 20){
        return this.$t('channel.IM.name')
      } else if (item.wechatType == 22){
        return this.$t('channel.zhiyuan.name')
      } else if (item.wechatType === 'askBot'){
        return this.$t('channel.askBot')
      }
    },
    getUrl(wechatType){
      if (wechatType == 0){
        return require(`../../assets/images/qiyechat.png`)
      } else if (wechatType == 1){
        return require(`../../assets/images/Wechat_128px_1230528.png`)
      } else if (wechatType == 2){
        return require(`../../assets/images/xiaochengxu.jpeg`)
      } else if (wechatType == 3 || wechatType == 6 || wechatType == 7 || wechatType == 9){
        return require(`../../assets/images/dingding.jpeg`)
      } else if (wechatType == 4){
        return require(`../../assets/images/webchat.png`)
      } else if (wechatType == 10){
        return require(`../../assets/images/feishu.webp`)
      } else if (wechatType == 13){
        return "iconfont guoran-yun"
      } else if (wechatType == 20){
        return "iconfont guoran-guanlixitong1"
      } else if (wechatType == 22){
        return require(`../../assets/images/zhiyuan.png`)
      } else if (wechatType === 'askBot'){
        return require('../../assets/images/fav.png')
      }
    },
    getList(){
      let url = this.requestUrl.bindV2.channelList;
      this.channelList = [];
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          let data = res.data.data.filter(item => item.wechatType !== 8 && item.wechatType !== 12 && item.wechatType !== 20)
          this.channelList = data;
        }
        this.getMini();
      })
    },
    getMini(){
      let url = this.requestUrl.bot.getBotList + '?page=1&size=1000&filterNotEnabledAppletBot=true';
      this.$http.get(url).then(res => {
        console.log('getMini',res)
        if (res.data.code == 0 && res.data.data){
          let data = res.data.data.list ? res.data.data.list : [];
          data.forEach(item => {
            item.wechatType = 2;
            item.agentName = item.name;
          })
          this.channelList = this.channelList.concat(data);
        }
        this.channelList = this.channelList.concat(this.askBot)
        this.loading = false;
      })
    },
    gotoConfig(item){
      let type = this.wechatTypeName(item.wechatType)
      this.$router.push({
        path:"/accessDetails",
        query:{
          type,
          bindCode:item.bindCode,
          id:item.id
        }
      })
    },
    wechatTypeName(type){
      switch (type) {
        case 0:
          return 'wechat'
        case 1:
          return 'wechatAccount'
        case 2:
          return 'miniProgram'
        case 3:
          return 'dingding'
        case 4:
          return 'website'
        case 6:
          return 'dingdingGroup'
        case 7:
          return 'dingdingGroup'
        case 9:
          return 'dingSingleChatBot'
        case 10:
          return 'feishu'
        case 13:
          return 'yunzhijia'
        case 20:
          return 'IM'
        case 22:
          return 'zhiyuan'
        case "askBot":
          return 'askBot'
      }
    },
    access(){
      this.$emit('access')
    }
  },
  mounted() {
    this.getList();
    this.askBot[0].agentName = this.$t('channel.askBotTips')
  }
};
</script>

<style scoped lang="less">
.channelSuccess{
  height: 100%;
  overflow-y: scroll;
  .list{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .cardItem{
      //flex: none;
      width: 258px;
      height: 258px;
      margin-bottom: 16px;
      margin-right: 16px;
      background: #FFFFFF;
      box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
      border-radius: 5px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      //justify-content: space-evenly;
      //justify-content: center;
      cursor: pointer;
      border: 2px solid #FFFFFF;
      img{
        flex: none;
        //width: 60px;
        height: 60px;
        margin-top: 20px;
      }
      .guoran-yun{
        font-size: 60px;
        color: rgb(60, 186, 255);
      }
      .guoran-guanlixitong1{
        font-size: 58px;
        color: rgb(9, 221, 213);
        margin-top: 20px;
      }
      .cardName{
        font-size: 18px;
        color: #000000;
        font-weight: bold;
        margin-top: 20px;
        text-align: center;
      }
      .cardTip{
        color: #A9B3C6;
        font-size: 12px;
        margin: 14px 0;
      }
      .agentName{
        color: #000000;
        //padding: 10px 0;
        margin: 14px 0;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .companyName{
        color: #A9B3C6;
        font-size: 12px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .cardItem:hover{
      border: 2px solid #B8CCFF;
    }
  }
  .empty{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
    border-radius: 5px;
    .tips{
      font-weight: bold;
      font-size: 24px;
      color: #616161;
      margin: 20px 0;
    }
    .accessBtn{
      height: 28px;
      border-radius: 18px;
      padding: 0 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #366aff;
      color: #ffffff;
      cursor:pointer;
    }
  }
}
</style>