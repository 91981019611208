<template>
  <div class="intelligentAgentManage" v-loading="loading">
    <div class="search" v-if="intelligentAgentList.length > 0 || keyword">
      <el-input v-model="keyword"
                placeholder="搜索智能体"
                @keyup.enter.native="search"
                prefix-icon="el-icon-search"
                size="medium"
                style="width: 80%"
                @input="searchNull"></el-input>
      <div class="addBtn" @click="addIntelligent">
        <i class="iconfont guoran-a-16-13"></i>
        新建智能体
      </div>
    </div>
    <template v-if="intelligentAgentList.length > 0">
      <div class="cardContainer">
        <div class="cardList">
          <div class="cardItem"
               v-for="item in intelligentAgentList"
               :key="item.id"
               @click="configFun(item)"
          >
            <img :src="item.logoUrl">
            <div class="name">{{item.agentName}}</div>
            <div class="description">{{item.description}}</div>
          </div>
        </div>
        <pagination
            :pageSize="pageSize"
            :currentPage="pageNum"
            :total="total"
            @currentChange="handleCurrentChange">
        </pagination>
      </div>
    </template>
    <div class="empty" v-else>
      <img src="../../assets/images/commonEmpty.png">
      <span class="empty-text">暂无数据</span>
      <div class="addBtn" @click="addIntelligent" v-if="!keyword">
        <i class="iconfont guoran-a-16-13"></i>
        新建智能体
      </div>
    </div>
    <popup @closeEvent="addIntelligentAgentDialog = false" v-if="addIntelligentAgentDialog" class="intelligent-agent-popup" :haveTips="false">
      <div slot="popup-name">新建智能体</div>
      <div slot="popup-con" class="popup-container">
        <el-form
            label-position="top"
            :model="formAgent">
          <el-form-item label="智能体名称">
            <span slot="label">
              智能体名称
              <span style="color: red">*</span>
            </span>
            <el-input v-model="formAgent.agentName" placeholder="请输入智能体名称"></el-input>
          </el-form-item>
          <el-form-item label="智能体描述">
            <span slot="label">
              智能体描述
              <span style="color: red">*</span>
            </span>
            <el-input v-model="formAgent.description" placeholder="请输入智能体描述"></el-input>
          </el-form-item>
          <el-form-item label="活动形式">
            <div slot="label" class="avatar-title">
              <span>智能体LOGO</span>
              <span style="color: red">*</span>
              <span class="tip">LOGO为方形的PNG文件，尺寸为256x256像素</span>
            </div>
            <div class="uploadLogo">
              <div class="avatarList">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    accept=".png"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                    :http-request="aliyunOssRequest"
                >
                  <img v-if="formAgent.logoUrl" :src="formAgent.logoUrl" class="avatar">
                  <div v-else class="avatar-uploader-icon">
                    <i class="el-icon-plus"></i>
                  </div>
                  <div class="re_upload_icon" v-if="formAgent.logoUrl">
                    <i class="iconfont guoran-youxuan"></i>
                  </div>
                </el-upload>
                <div class="aiImageList">
                  <div class="aiImageItem"
                       v-for="(item,index) in imageList"
                       :key="index"
                       :class="formAgent.logoUrl == item.url ? 'activeImage' : ''"
                       @click="setLogo(item)"
                  >
                    <el-image
                        style="width: 120px; height: 120px;"
                        :src="item.url"
                        fit="cover"></el-image>
                  </div>
                </div>
              </div>
              <div class="aiBtn" @click="aiImageFun">
                {{ AILoading ?
                  $t('channel.botAiBtnStatus0') :
                  $t('channel.botAiBtnStatus1') }}
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="addIntelligentAgentDialog = false" class="cancel-btn">取消</el-button>
        <el-button type="primary" @click="confirmSave" class="confirm-btn">确认</el-button>
      </div>
    </popup>
  </div>
</template>

<script>
import Popup from "../../components/popupNew";
import { requestUrl } from "../../api/requestUrl";
import { multipartUpload, ossConfig } from "../../assets/js/AliyunIssUtil";
import pagination from "../../components/pagination";
export default {
  name: "intelligentAgentManage",
  data(){
    return{
      keyword:"",
      loading:false,
      intelligentAgentList:[],
      addIntelligentAgentDialog:false,
      formAgent:{
        agentName:"",
        description:"",
        logoUrl:"",
        bindBot:null,
      },
      imageList:[],
      pageNum:0,
      pageSize:20,
      total:0,
      botList:[],
      AILoading:false
    }
  },
  components:{
    Popup,
    pagination
  },
  methods:{
    configFun(item){
      this.$router.push({
        path:"/intelligentAgentConfig",
        query:{
          id:item.id
        }
      })
    },
    search(){
      if (!this.keyword){
        return
      }
      this.pageNum = 0;
      this.getIntelligentAgent();
    },
    searchNull(){
      if (!this.keyword){
        this.pageNum = 0;
        this.getIntelligentAgent();
      }

    },
    handleCurrentChange(val){
      this.pageNum = val;
      this.getIntelligentAgent();
    },
    addIntelligent(){
      this.addIntelligentAgentDialog = true;
      this.formAgent = {
        agentName:"",
        description:"",
        logoUrl:"",
        bindBot:null,
      }
    },
    async beforeAvatarUpload(file){
      const istype = file.type === 'image/png'
      const isSize = await new Promise(function (resolve, reject) {
        let width = 256
        let height = 256
        let _URL = window.URL || window.webkitURL
        let image = new Image()

        image.onload = function () {
          const valid =
              image.width <= width &&
              image.height <= height &&
              image.width / image.height == 1
          console.log('valid',valid,image.width)
          valid ? resolve() : reject()
        }
        image.src = _URL.createObjectURL(file);
      }).then(
          () => {
            return file
          },
          () => {
            this.$message({
              message:this.$t('channel.avatarSizeError'),
              type:"error"
            })
            return Promise.reject(false)
          }
      )

      if (!istype) {
        this.$message({
          message:this.$t('channel.avatarTypeErr'),
          type:"error"
        })
      }

      return isSize && istype
    },
    handleAvatarSuccess(res){
      console.log('handleAvatarSuccess',res)
      let url = res.res.requestUrls[0];
      let index = url.lastIndexOf("?")
      if (index !== -1){
        url = url.substr(0, url.lastIndexOf("?"));
      }
      this.$set(this.formAgent,'logoUrl',url);
    },
    aliyunOssRequest(data){
      let file = data.file;
      let res = multipartUpload(ossConfig,file);
      console.debug('oss upload res', data, res);
      return res
    },
    setLogo(item){
      this.$set(this.formAgent,'logoUrl',item.url);
    },
    getBotList(){
      this.$http.get(this.requestUrl.bot.getBotList, {
        page: 1,
        size: 100,
      }).then(res => {
        if (res.data.code == 0 && res.data.data && res.data.data.list){
          this.botList = res.data.data.list;
        }
      })
    },
    confirmSave(){
      if (!this.formAgent.agentName){
        this.$message({
          message:"智能体名称是必填的",
          type:"warning"
        })
        return
      }
      if (!this.formAgent.description){
        this.$message({
          message:"智能体描述是必填的",
          type:"warning"
        })
        return
      }
      if (!this.formAgent.logoUrl){
        this.$message({
          message:"智能体LOGO是必填的",
          type:"warning"
        })
        return
      }
      let url = requestUrl.intelligentAgent.saveIntelligentAgent;
      let bindBot = {
        agentBindBotInfos:[]
      }
      if (this.botList.length > 0){
        let obj = {
          apiKey:this.botList[0].apiKey,
          botId:this.botList[0].id,
          scope:0,
          botType:0,
          name:this.botList[0].name,
          conditions:[{
            expect:[],
            key:"",
            relation:"CONTAIN_ANY",
            type:"ALL",
            visible:false
          }]
        }
        bindBot.agentBindBotInfos = [obj];
        this.formAgent.bindBot = bindBot;
      }
      if (this.AILoading){
        this.$message({
          message:"AI生成LOGO中，请稍后",
          type:"warning"
        })
        return;
      }
      this.$http.post(url,this.formAgent).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:"保存成功",
            type:"success"
          })
          this.pageNum = 0;
          this.getIntelligentAgent();
          this.addIntelligentAgentDialog = false;
        }

      })

    },
    getIntelligentAgent(){
      let url = requestUrl.intelligentAgent.getList;
      url += '?keyWord=' + this.keyword + '&pageNum=' + this.pageNum + '&pageSize=' + this.pageSize;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.intelligentAgentList = res.data.data.list;
          this.total = res.data.data.total;
        }
      })
    },
    aiImageFun() {
      let url = "/tag-api/model/generate-logo";
      if (!this.formAgent.agentName && !this.formAgent.description) {
        this.$message({
          message: this.$t('channel.atLeastOneInput'),
          type: "warning",
        });
        return;
      }
      let obj = {
        prompt:
            "帮我生成一个智能体LOGO图片，正方形，简单风格，色彩三种以内，透明色背景，图片内容体现如下信息:" +
            this.formAgent.agentName +
            "," +
            this.formAgent.description,
        uid: localStorage.getItem("_uid"),
      };
      this.AILoading = true;
      this.$http.post(url, obj).then((res) => {
        this.AILoading = false;
        if (res.data) {
          this.imageList = [
            {
              url: res.data,
            },
          ];
        }
      }).catch(() => {
        this.$message({
          message:this.$t('channel.generationFailed'),
          type:"warning"
        })
        this.AILoading = false;
      });
    },
  },
  mounted() {
    this.getIntelligentAgent();
    this.getBotList();
  },
  activated() {
    this.pageNum = 0;
    this.intelligentAgentList = [];
    this.getIntelligentAgent();
    sessionStorage.removeItem('deleteIntelligent');
  }
};
</script>

<style scoped lang="less">
.intelligentAgentManage{
  height: 100%;
  overflow: hidden;
  .search{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 14px;
    /deep/.el-input__inner{
      border: none;
    }
  }
  .addBtn{
    flex: none;
    //margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 10px;
    background-color: #366aff;
    color: #FFFFFF;
    border-radius: 15px;
    cursor: pointer;
    i{
      padding-right: 4px;
      color: #FFFFFF;
    }
  }
  .cardContainer{
    height: calc(100% - 68px);
    .cardList{
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      height: calc(100% - 50px);
      overflow-y: scroll;
      .cardItem{
        //flex: none;
        width: 258px;
        height: 258px;
        margin-bottom: 16px;
        margin-right: 16px;
        background: #FFFFFF;
        box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
        border-radius: 5px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        //justify-content: space-evenly;
        //justify-content: center;
        cursor: pointer;
        border: 2px solid #FFFFFF;
        img{
          flex: none;
          //width: 60px;
          height: 60px;
          margin-top: 20px;
        }
        .guoran-yun{
          font-size: 60px;
          color: rgb(60, 186, 255);
        }
        .guoran-guanlixitong1{
          font-size: 58px;
          color: rgb(9, 221, 213);
          margin-top: 20px;
        }
        .name{
          font-size: 18px;
          color: #000000;
          font-weight: bold;
          margin-top: 20px;
          text-align: center;
          margin-bottom: 20px;
        }
        .description{
          color: #A9B3C6;
          font-size: 12px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .cardItem:hover{
        border: 2px solid #B8CCFF;
      }
    }
  }
  .empty{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
    border-radius: 5px;
    .empty-text{
      margin: 20px 0;
      font-size: 16px;
    }
    .tips{
      font-weight: bold;
      font-size: 24px;
      color: #616161;
      margin: 20px 0;
    }
    .accessBtn{
      height: 28px;
      border-radius: 18px;
      padding: 0 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #366aff;
      color: #ffffff;
      cursor:pointer;
    }
  }
  #pagination{
    background-color: transparent;
    box-shadow: none;
  }
}
.intelligent-agent-popup{
  #popup-assembly-new{
    width: 790px !important;
  }
  .popup-container{
    .avatar-title{
      display: flex;
      align-items: center;
      .tip{
        margin-left: 10px;
        font-size: 12px;
        color: #A9B3C6;
      }
    }
    /deep/.el-form-item__label{
      line-height: 20px;
    }
    .uploadLogo{
      display: flex;
      flex-direction: column;
      .avatar-uploader{
        margin-right: 10px;
        .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .re_upload_icon{
          height: 120px;
          width: 120px;
          display: none;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 5px;
          i {
            color: #ffffff;
          }
        }
        .el-upload:hover {
          border-color: #409EFF;
          .re_upload_icon{
            display: flex;
          }
        }
        .avatar-uploader-icon {
          color: #8c939d;
          width: 120px;
          height: 120px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px dashed #d9d9d9;
          .el-icon-plus{
            font-size: 28px;
            //color: #366aff;
            margin-bottom: 10px;
          }
          .tips{
            margin-bottom: 6px;
          }
        }
        .avatar {
          width: 120px;
          height: 120px;
          display: block;
        }
      }
      .aiImageList{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .aiImageItem{
          margin-right: 10px;
          border: 1px solid #ffffff;
        }
        .activeImage{
          .el-image{
            border: 1px solid #366aff;
          }
        }

      }
      .logoTips{
        display: flex;
        flex-direction: column;
        color: #A9B3C6;
      }
      .avatarList{
        display: flex;
      }
      .aiBtn{
        flex: none;
        margin-top: 10px;
        padding: 0 10px;
        max-width: 80px;
        height: 30px;
        background: #366AFF;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

}
</style>